import React from "react";
import "./settingModal.css";

const SettingModal = ({
  isOpen,
  onClose,
  slippage,
  deadline,
  setSlippage,
  setDeadline,
  autoSlippage,
}) => {
  return (
    <div className={`settings_box ${isOpen ? "open" : ""}`}>
      <div className="setting_heading">
        <p>Settings</p>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>

      <div className="slip_transaction_container">
        {autoSlippage !== 10 && (
          <div>
            <p>Slippage Tolerance</p>
            <div className="slip_button">
              <button onClick={() => setSlippage(autoSlippage)}>Auto</button>
              <input
                type="number"
                name="slippage"
                value={slippage}
                onChange={(e) => setSlippage(e.target.value)}
              />
            </div>
          </div>
        )}

        <div>
          <p>Transaction Deadline</p>
          <div className="transaction_deadline">
            <input
              type="number"
              name="transaction"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
            <p>Minutes</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingModal;
