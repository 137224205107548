import React, { createContext, useState, useEffect } from "react";
import { useNetwork, useProvider, useSigner } from "wagmi";
import axios from "axios";
import network from "../network";
import { ethers } from "ethers";
import routerAbi from "../abi/routerAbi.json";
import tokenAbi from "../abi/tokenAbi.json";
import toast from "react-hot-toast";

// Create a new context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const { data: signer } = useSigner();
  const provider = useProvider();
  const { chain } = useNetwork();

  const [tokenList, setTokenList] = useState(network[chain?.id]?.tokens);

  const [amt1, setAmt1] = useState("");
  const [amt2, setAmt2] = useState("");
  const [approx, setApprox] = useState(0);
  const [ethInUsd, setEthInUsd] = useState(0);

  const [info1, setInfo1] = useState({ decimals: 0, symbol: "" });
  const [info2, setInfo2] = useState({ decimals: 0, symbol: "" });

  const [debounceTimeoutA, setDebounceTimeoutA] = useState(null);
  const [debounceTimeoutB, setDebounceTimeoutB] = useState(null);

  const [slippage, setSlippage] = useState(1);
  const [deadline, setDeadline] = useState(30);
  const [loading, setLoading] = useState(false);

  const [coinPanel, setCoinPanel] = useState({ isOpen: false, coin: 1 });

  const token1 = new ethers.Contract(
    tokenList[0]?.address,
    tokenAbi,
    signer || provider
  );
  const token2 = new ethers.Contract(
    tokenList[1]?.address,
    tokenAbi,
    signer || provider
  );

  const router = new ethers.Contract(
    network[chain?.id]?.router,
    routerAbi,
    signer || provider
  );

  const wethAddress = network[chain?.id]?.weth;

  // disable scroll when loading
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup the effect when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [loading]);

  useEffect(() => {
    // calculate eth in usd
    axios
      .get(
        `https://api.dexscreener.com/latest/dex/tokens/${
          network[chain?.id]?.weth
        }`
      )
      .then((res) => {
        setEthInUsd(Number(res.data?.pairs[0]?.priceUsd));
      })
      .catch((err) => console.log("axios error", err));

    setTokenList(network[chain?.id]?.tokens);
  }, [chain?.id]);

  // if token changes fetch decimal and symbol
  useEffect(() => {
    setAmt1("");
    setAmt2("");
    setApprox(0);

    fetchToken(token1, setInfo1, 1);
    fetchToken(token2, setInfo2, 2);
  }, [tokenList]);

  /**
   * changes the value of amt1 and calculates amt2
   * @param {*} value
   */
  function handleInput1(value) {
    setAmt1(value);
    if (value === "") {
      setAmt2(value);
      clearTimeout(debounceTimeoutA);
      setApprox(0);
      return;
    }

    if (token1.address === token2.address) {
      setAmt2(value);
      setApprox(value);
      return;
    }

    clearTimeout(debounceTimeoutA);
    let path = [token1.address, token2.address];

    // for token to token conversion
    if (token1.address !== wethAddress && token2.address !== wethAddress) {
      path = [token1.address, wethAddress, token2.address];
    }

    const timeout = setTimeout(async () => {
      try {
        const amountIn = ethers.utils.parseUnits(value, info1.decimals);
        const amounts = await router.getAmountsOut(amountIn, path);
        setAmt2(
          ethers.utils.formatUnits(amounts[amounts.length - 1], info2.decimals)
        );

        // if token1 is weth
        if (token1.address === wethAddress) {
          setApprox(value);
          return;
        }

        const ethValue = await router.getAmountsOut(amountIn, [
          token1.address,
          wethAddress,
        ]);
        setApprox(ethers.utils.formatEther(ethValue[1]));
      } catch (error) {
        console.log("input1");
        console.dir(error);
        toast.error("Cannot fetch this token");
      }
    }, 500);
    setDebounceTimeoutA(timeout);
  }

  /**
   * changes the value of amt2 and calculates amt1
   * @param {*} value
   */
  function handleInput2(value) {
    setAmt2(value);
    if (value === "") {
      setAmt1(value);
      clearTimeout(debounceTimeoutB);
      setApprox(0);
      return;
    }

    // wbnb and eth
    if (token1.address === token2.address) {
      setAmt2(value);
      setApprox(value);
      return;
    }

    clearTimeout(debounceTimeoutB);
    let path = [token2.address, token1.address];

    // for token to token conversion
    if (token1.address !== wethAddress && token2.address !== wethAddress) {
      path = [token2.address, wethAddress, token1.address];
    }

    const timeout = setTimeout(async () => {
      try {
        const amountIn = ethers.utils.parseUnits(value, info2.decimals);
        const amounts = await router.getAmountsOut(amountIn, path);
        setAmt1(
          ethers.utils.formatUnits(amounts[amounts.length - 1], info1.decimals)
        );

        // if token2 is weth
        if (token2.address === wethAddress) {
          setApprox(value);
          return;
        }

        const ethValue = await router.getAmountsOut(amountIn, [
          token2.address,
          wethAddress,
        ]);
        setApprox(ethers.utils.formatEther(ethValue[1]));
      } catch (error) {
        console.log("input2");
        console.dir(error);
        toast.error("Cannot fetch this token");
      }
    }, 500);
    setDebounceTimeoutB(timeout);
  }

  /**
   * fetches decimal and symbol of token
   * @param {*} token
   * @param {*} setToken
   */
  async function fetchToken(token, setInfo, n) {
    let res;
    try {
      res = await token.decimals();
    } catch (error) {
      console.log(error);
    } finally {
      setInfo({
        decimals: res,
        symbol: tokenList[n - 1]?.symbol,
        img: tokenList[n - 1]?.img,
      });
    }
  }

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    backdropFilter: "blur(5px)",
    display: loading ? "block" : "none", // Show/hide based on the loading prop
  };

  // Define the values and functions to be shared
  const contextValue = {
    token1,
    token2,
    ethInUsd,
    amt1,
    amt2,
    approx,
    handleInput1,
    handleInput2,
    info1,
    info2,
    setInfo1,
    setInfo2,
    slippage,
    setSlippage,
    deadline,
    setDeadline,
    coinPanel,
    setCoinPanel,
    tokenList,
    setTokenList,
    router,
    provider,
    signer,
    wethAddress,
    loading,
    setLoading,
    loaderStyle,
  };
  console.log(info1, info2, token1, token2);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
