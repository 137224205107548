
import "./footerMain.css"

import { GrInstagram, GrTwitter } from "react-icons/gr";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go"
import { useState } from "react";
import logoNew from "../images/logo_new.png"
import footeGlobeImage from "../images/footerGlobeImage.svg"
import toast, { Toaster } from "react-hot-toast";
import { TbWorldWww } from "react-icons/tb"
import { BiLogoTelegram } from "react-icons/bi"
import { MdEmail } from "react-icons/md"

import socialLinks from "../../socialLinks.json"


const FooterMian = () => {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState('');
    const [openSection, setOpenSection] = useState(null);

    const handleAccordionClick = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Call Pabbly API endpoint to add the email to your list
        try {
            const apiURL = "https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZlMDYzNjA0MzU1MjZlNTUzYzUxMzMi_pc"
            const response = await fetch(apiURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                // Handle successful submission, e.g., show a success message
                console.log('Email successfully subscribed!');
                toast.success('Email successfully subscribed!');
            } else {
                // Handle errors, e.g., show an error message
                console.log('Failed to subscribe email.');
                toast.error('Failed to subscribe email.')
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    return (
        <section className="mainFooter">
            <Toaster />
            <img src={footeGlobeImage} alt="footerImage" className="footerBackgroundGlobe" />
            <div className="footerContainer">
                <div className="footerContent">
                    <a href={socialLinks.homeLink} target="_blank" rel="noopener noreferrer" className="footerMainLogo">
                        <img src={logoNew} alt="footerLogo" />
                    </a>


                    <div className="footerMainContentLinks">
                        <div
                            className={`accordion ${openSection === 'about' ? 'open' : ''}`}
                            onClick={() => handleAccordionClick('about')}
                        >
                            <div className="accordionHeader">About Us
                                <span className="accordiionArrow">{openSection === "about" ? <GoTriangleUp /> : <GoTriangleDown />}</span>
                            </div>
                            <div className="accordionContent">
                                <a href={socialLinks.homeLink} target="_blank" rel="noopener noreferrer">Noe Global</a>
                                <a href={socialLinks.whoWeAre} target="_blank" rel="noopener noreferrer">Who are we?</a>
                                <a href={socialLinks.homeLink} target="_blank" rel="noopener noreferrer">Our Services</a>
                            </div>
                        </div>

                        <div
                            className={`accordion ${openSection === 'term' ? 'open' : ''}`}
                            onClick={() => handleAccordionClick('term')}
                        >
                            <div className="accordionHeader">Terms
                                <span className="accordiionArrow">{openSection === "term" ? <GoTriangleUp /> : <GoTriangleDown />}</span>
                            </div>
                            <div className="accordionContent">
                                <a href={socialLinks.terms} target="_blank" rel="noopener noreferrer">Terms and conditions</a>
                                <a href={socialLinks.personalAccount} target="_blank" rel="noopener noreferrer">Open a Personal Account</a>
                                <a href={socialLinks.businessAccount} target="_blank" rel="noopener noreferrer">Open a Business Account</a>
                            </div>
                        </div>

                        <div
                            className={`accordion ${openSection === 'resources' ? 'open' : ''}`}
                            onClick={() => handleAccordionClick('resources')}
                        >
                            <div className="accordionHeader">Resources
                                <span className="accordiionArrow">{openSection === "resources" ? <GoTriangleUp /> : <GoTriangleDown />}</span>
                            </div>
                            <div className="accordionContent">
                                <a href={socialLinks.whitepaper} target="_blank" rel="noopener noreferrer">Whitepaper</a>
                                <a href={socialLinks.noeToken} target="_blank" rel="noopener noreferrer">NOE Token</a>
                            </div>
                        </div>
                    </div>

                    <div className="footerNewsletter">
                        <p>Subscribe</p>
                        <p>Join our mailing list for the latest updates and promotions.</p>
                        <form onSubmit={handleSubmit}>
                            <input type="email" placeholder="Enter your email" value={email}
                                onChange={handleInputChange}
                                required />
                            <button className="newsLetterSubscribe" type="submit">SUBSCRIBE</button>
                        </form>
                        <p>By subscribing, you agree to our Privacy Policy and consent to receive updates from us</p>
                    </div>
                </div>
                <div className="footerMainSocialHandle">
                    <p>© {currentYear} NOE GLOBAL. All rights reserved</p>
                    <div className="footerMainSociaLIcon">
                        <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"><GrInstagram /></a>
                        <a href={socialLinks.homeLink} target="_blank" rel="noopener noreferrer"><TbWorldWww /></a>
                        <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer"><GrTwitter /></a>
                        <a href={socialLinks.telegram} target="_blank" rel="noopener noreferrer"><BiLogoTelegram /></a>
                        <a href="mailto:contact@noe-global.com" ><MdEmail /></a>
                        {/* <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer"><GrYoutube /></a> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FooterMian