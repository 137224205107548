import React, { useState } from "react";
import "./CoinModal.css";
import "../settingModal/settingModal.css";
import { AppContext } from "../../context/AppContext";
import { useNetwork } from "wagmi";
import { useContext } from "react";
import tokenImage from "../images/unknownToken.png";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import tokenAbi from "../../abi/tokenAbi.json";

const CoinModal = () => {
  const { coinPanel, setCoinPanel, tokenList, setTokenList, provider } =
    useContext(AppContext);
  const [input, setInput] = useState("");
  const [options, setOptions] = useState(
    tokenList.map((token, i) => ({ ...token, index: i }))
  );

  useEffect(() => {
    setOptions(tokenList.map((token, i) => ({ ...token, index: i })));
  }, [tokenList]);

  if (!coinPanel.isOpen) {
    return null;
  }

  async function handleKeyDown(e) {
    if (e.key === "Enter") {
      try {
        if (tokenList.some((token) => token.address === input)) {
          toast.error("Token already present");
          return;
        }

        const token = new ethers.Contract(input, tokenAbi, provider);
        const symbol = await token.symbol();
        setTokenList((prev) => {
          const newTokenList = [...prev];
          newTokenList.push({
            address: input,
            img: tokenImage,
            symbol,
          });
          return newTokenList;
        });
        setInput("");
        toast.success("Token imported successfully");
      } catch (error) {
        console.log(error);
        toast.error(error?.reason || error?.message);
      }
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setInput(value);
    setOptions(
      tokenList
        .map((token, i) => ({ ...token, index: i }))
        .filter((token) =>
          token.symbol.toLowerCase().includes(value.toLowerCase())
        )
    );
  }

  return (
    <div className="coinModal_box">
      <div>
        <h3>Select a token</h3>
        <h3
          onClick={() => {
            setCoinPanel((prev) => ({ ...prev, isOpen: false }));
          }}
        >
          x
        </h3>
      </div>

      <input
        type="text"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={input}
        placeholder="Enter address to import or search"
      />

      <div className="coinModal_token_list">
        {options.map((token, i) => {
          i = token.index;
          return (
            <div
              onClick={() => {
                if (coinPanel.coin === 1) {
                  // set coin 1 (replace 0th index with ith index)
                  setTokenList((prev) => {
                    const temp = prev[0];
                    prev[0] = prev[i];
                    prev[i] = temp;
                    return [...prev];
                  });
                }
                // set coin 2 (replace 1th index with ith index)
                if (coinPanel.coin === 2) {
                  setTokenList((prev) => {
                    const temp = prev[1];
                    prev[1] = prev[i];
                    prev[i] = temp;
                    return [...prev];
                  });
                }
                setCoinPanel((prev) => ({ ...prev, isOpen: false }));
              }}
            >
              <img src={token.img} alt="" />
              <p>{token.symbol}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CoinModal;
