
import { Link } from '@reach/router';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import "./headerMain.css";

import socialLinks from "../../socialLinks.json";
import logo_swap from "../images/logo_swap1.png";
const HeaderMain = () => {
    const activeOption = localStorage.getItem('activeOption') || 'home';
    const [menuOpen, setMenuOpen] = useState(false);
    const [active, setActive] = useState(activeOption);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };


    const handleClick = (option) => {
        localStorage.setItem('activeOption', option)
        setActive(option);
    };
    return (
        <nav className="navbarMain">
            <div className="navbarContainer">
                <div className='logoNavOption'>
                    <div className="logoNav">
                        <a href={'/'} rel="noopener noreferrer" onClick={() => { closeMenu(); handleClick("home"); }}>
                            <img src={logo_swap} alt="logoNew" />
                        </a>
                    </div>
                    <div className={`menuNav ${menuOpen ? "active" : ""}`}>
                        <Link to="/" onClick={() => handleClick("home")} className={active === "home" ? "activedOp" : ""}>
                            Home
                        </Link>
                        <Link to="/staking" onClick={() => handleClick("staking")} className={active === "staking" ? "activedOp" : ""}>
                            Stake
                        </Link>
                        <Link to="/swap" onClick={() => handleClick("swap")} className={active === "swap" ? "activedOp" : ""}>
                            Swap
                        </Link>
                        <Link to="/liquidity" onClick={() => handleClick("liquidity")} className={active === "liquidity" ? "activedOp" : ""}>
                            Liquidity
                        </Link>

                    </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                    <div className='mainNavButtons deskTabDeviceButton'>
                        <a href={socialLinks.homeLink} target="_blank" rel="noopener noreferrer" className="exploreNavButton" onClick={closeMenu}>Explore the NOE Global</a>

                        {/* <button className="connectNavWallet" onClick={closeMenu}>Connect Wallet</button> */}
                        <div className="connectNavWallet">
                            <ConnectButton />
                        </div>
                    </div>

                    <div className="menuIcon" onClick={handleMenuToggle}>
                        {menuOpen ? <IoCloseSharp size={"3rem"} /> : <GiHamburgerMenu size={"3rem"} />}
                    </div>
                    {menuOpen && (
                        <div className="mobileMenu">
                            <Link to="/" onClick={() => { closeMenu(); handleClick("home") }} className={active === "home" ? "activedOp" : ""}>
                                Home
                            </Link>
                            <Link to="/staking" onClick={() => { closeMenu(); handleClick("staking") }} className={active === "staking" ? "activedOp" : ""}>
                                Stake
                            </Link>
                            <Link to="/swap" onClick={() => { closeMenu(); handleClick("swap") }} className={active === "swap" ? "activedOp" : ""}>
                                Swap
                            </Link>

                            <Link to="/liquidity" onClick={() => { closeMenu(); handleClick("liquidity") }} className={active === "liquidity" ? "activedOp" : ""}>
                                Liquidity
                            </Link>


                            <div className='mainNavButtons mobileDeviceButton'>
                                <button className="exploreNavButton" onClick={closeMenu}>Explore the NOE Global</button>

                                {/* <button className="connectNavWallet" onClick={closeMenu}>Connect Wallet</button> */}
                                <div className="connectNavWallet">
                                    <ConnectButton />
                                </div>
                            </div>
                        </div>

                    )}
                </div>

            </div>
        </nav>
    )
}

export default HeaderMain