import React from "react";
import "./App.css";
import "@rainbow-me/rainbowkit/dist/index.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { darkTheme } from "@rainbow-me/rainbowkit";
import Staking from "./components/Staking";
import Earn from "./components/Earn";
// import Header from "./components/Header/Index";
// import Footer from "./components/Footer/index";
import { Router, Location } from "@reach/router";
import "./components/assets/style.scss";
import Swap from "./components/Swap/Swap";
import Liquidity from "./components/Liquidity/Liquidity";
import { AppProvider } from "./context/AppContext";
import { ethers } from "ethers";
import HomePage from "./components/Home/HomePage";
import HeaderMain from "./components/HeaderMain/HeaderMain";
import FooterMian from "./components/FooterMain/FooterMian";

function App() {
  const BSCchain = {
    id: 56,
    name: "BNB Smart Chain",
    network: "BSC",
    iconUrl:
      "https://user-images.githubusercontent.com/12424618/54043975-b6cdb800-4182-11e9-83bd-0cd2eb757c6e.png",
    iconBackground: "#fff",
    nativeCurrency: {
      decimals: 18,
      name: "Binance Smart Chain",
      symbol: "BNB",
    },
    rpcUrls: {
      default: "https://bsc-dataseed.binance.org/",
    },
    blockExplorers: {
      default: { name: "bscScan", url: "https://bscscan.com" },
      etherscan: { name: "bscScan", url: "https://bscscan.com" },
    },
    testnet: false,
  };

  const BSCT = {
    id: 97,
    name: "BSCT",
    network: "BSCT",
    iconUrl:
      "https://user-images.githubusercontent.com/12424618/54043975-b6cdb800-4182-11e9-83bd-0cd2eb757c6e.png",
    iconBackground: "#fff",
    nativeCurrency: {
      decimals: 18,
      name: "Binance Smart Chain Testnet",
      symbol: "BNBT",
    },
    rpcUrls: {
      default: "https://data-seed-prebsc-1-s1.binance.org:8545",
    },
    testnet: true,
  };

  const Polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    iconUrl:
      "https://app.uniswap.org/static/media/matic-token-icon.da7b877d41122a55de4a43760bb4c8e5.svg",
    iconBackground: "#fff",
    nativeCurrency: {
      decimals: 18,
      name: "Matic",
      symbol: "MATIC",
    },
    rpcUrls: {
      default: "https://rpc.ankr.com/polygon",
    },
    testnet: false,
  };

  const Mainnet = {
    id: 1,
    name: "Ethereum Mainnet",
    network: "homestead",
    iconUrl: "https://assets.pancakeswap.finance/web/native/1.png",
    iconBackground: "#fff",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
    rpcUrls: {
      default: "https://rpc.ankr.com/eth",
    },
    testnet: false,
  };

  if (process.env.REACT_APP_STATUS === "production") {
    console.log = () => { };
    console.error = () => { };
    console.debug = () => { };
    console.warn = () => { };
    console.dir = () => { };
  }

  const { chains, provider } = configureChains(
    [
      BSCchain,
      Polygon,
      Mainnet,
      // chain.polygon,
      // chain.mainnet,
      // chain.optimism,
      // chain.arbitrum,
      // BSCT,
    ],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "NOE",
    chains,
  });
  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  const PosedRouter = ({ children }) => (
    <Location>
      {({ location }) => (
        <div id="routerhang">
          <div key={location.key}>
            <Router location={location}>{children}</Router>
          </div>
        </div>
      )}
    </Location>
  );

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          borderRadius: "medium",
          accentColor: "#e8b124",
          accentColorForeground: "white",
        })}
      >
        <AppProvider>
          <HeaderMain />
          <PosedRouter>
            <HomePage exact path="/" />
            <Swap exact path="/swap" />
            <Liquidity exact path="/liquidity" />
            <Staking exact path="/staking" />
            {/* <Earn exact path="/earn" /> */}
          </PosedRouter>
          <FooterMian />
        </AppProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
