import React, { useContext, useEffect, useState } from "react";
import "./liquidity.css";

import liquiditysetting from "../images/Settings_swap.png";
import LiquidityPlus from "../images/LiquidityPlus.png";
import swap_ethereium_icon from "../images/swap_ethereum.png";
import swap_usdt from "../images/usdt.png";
import SettingModal from "../settingModal/SettingModal";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { AppContext } from "../../context/AppContext";
import { useAccount, useNetwork } from "wagmi";
import CoinModal from "../coinModal/CoinModal";
import toast, { Toaster } from "react-hot-toast";
import network from "../../network";
import { ethers } from "ethers";
import tokenAbi from "../../abi/tokenAbi.json";
import { ColorRing } from "react-loader-spinner";

const Liquidity = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();
  const [share, setShare] = useState(0);

  const toggleSettingsBox = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const {
    tokenList,
    token1,
    token2,
    ethInUsd,
    amt1,
    amt2,
    approx,
    handleInput1,
    handleInput2,
    info1,
    info2,
    slippage,
    setSlippage,
    deadline,
    setDeadline,
    setCoinPanel,
    router,
    provider,
    loading,
    setLoading,
    loaderStyle,
  } = useContext(AppContext);

  useEffect(() => {
    if (!amt1 || !amt2) {
      setShare(0);
      return;
    }

    // calculates the share of pool
    (async () => {
      try {
        const factoryAddress = await router.factory();
        const factory = new ethers.Contract(
          factoryAddress,
          [
            "function getPair(address tokenA, address tokenB) external view returns (address pair)",
          ],
          provider
        );
        const pairAddress = await factory.getPair(
          token1.address,
          token2.address
        );
        if (pairAddress === ethers.constants.AddressZero) {
          return;
        }

        const pair = new ethers.Contract(
          pairAddress,
          [
            "function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)",
          ],
          provider
        );
        const reserves = await pair.getReserves();
        setShare(
          ethers.utils
            .parseUnits(amt1, info1.decimals)
            .mul(ethers.utils.parseUnits(amt2, info2.decimals))
            .mul("100")
            .div(reserves[0])
            .div(reserves[1])
            .toNumber()
        );
      } catch (error) {
        setShare(0);
        console.log("share", error);
      }
    })();
  }, [amt1, amt2]);

  async function handleAdd() {
    try {
      if (token1.address === token2.address) {
        toast.error("Please select different tokens");
        return;
      }

      if (!amt1 || !amt2) {
        toast.error("Please enter amount");
        return;
      }

      setLoading(true);
      let txn;
      const dl = Math.floor(Date.now() / 1000) + deadline * 60;

      // addliquidityeth
      if (
        token1.address === network[chain?.id]?.weth ||
        token2.address === network[chain?.id]?.weth
      ) {
        const bool = token1.address === network[chain?.id]?.weth;
        const token = bool ? token2 : token1;

        // approve the token
        const allowance = await token.allowance(address, router.address);

        if (
          allowance.lt(
            ethers.utils.parseUnits(
              bool ? amt2 : amt1,
              bool ? info2.decimals : info1.decimals
            )
          )
        ) {
          txn = await token.approve(
            router.address,
            ethers.constants.MaxUint256
          );
          await txn.wait();
        }

        txn = await router.addLiquidityETH(
          bool ? token2.address : token1.address,
          ethers.utils.parseUnits(
            bool ? amt2 : amt1,
            bool ? info2.decimals : info1.decimals
          ),
          1,
          1,
          address,
          dl,
          {
            value: ethers.utils.parseEther(bool ? amt1 : amt2),
            // gasPrice: await provider.getGasPrice(),
          }
        );
        await txn.wait();
        setLoading(false);
        return;
      }

      // addliquidity
      let allowance1 = token1.allowance(address, router.address);
      let allowance2 = token2.allowance(address, router.address);
      const res = await Promise.allSettled([allowance1, allowance2]);
      allowance1 = res[0].value;
      allowance2 = res[1].value;

      if (allowance1.lt(ethers.utils.parseUnits(amt1, info1.decimals))) {
        txn = await token1.approve(router.address, ethers.constants.MaxUint256);
        await txn.wait();
      }

      if (allowance2.lt(ethers.utils.parseUnits(amt2, info2.decimals))) {
        txn = await token2.approve(router.address, ethers.constants.MaxUint256);
        await txn.wait();
      }

      txn = await router.addLiquidity(
        token1.address,
        token2.address,
        ethers.utils.parseUnits(amt1, info1.decimals),
        ethers.utils.parseUnits(amt2, info2.decimals),
        1,
        1,
        address,
        dl,
        {
          // gasPrice: await provider.getGasPrice(),
        }
      );
      await txn.wait();
      toast.success("Liquidity added successfully");
    } catch (error) {
      console.log("handleadd", error);
      toast.error(error?.reason || error?.message);
    } finally {
      setLoading(false);
    }
  }

  // const liquidityDescription = {
  //   desc: `In this remarkable Liquidity Feature, you have the exciting opportunity to participate in two distinct activities: adding liquidity to an existing pair, commonly referred to as farming, and generating passive income through LP token holdings. Alternatively, you can opt to initiate an entirely new pair for launch on the Decentralized Exchange platform.

  //   Liquidity, in the context of cryptocurrencies and financial instruments, denotes the ease with which these assets can be transacted in the market without causing significant price fluctuations.

  //   High liquidity signifies a robust presence of both buyers and sellers, fostering swift and seamless trade execution. Conversely, low liquidity may result in price volatility and challenges in executing substantial orders. The level of liquidity bears immense significance for traders and investors, exerting a substantial influence on market stability and the overall utility of these assets.`,
  // };

  return (
    <>
      <ColorRing
        height="60"
        width="60"
        ariaLabel="blocks-loading"
        colors={["#000"]}
        wrapperStyle={loaderStyle}
        visible={loading}
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
          style: {
            color: "#000",
            fontSize: "14px",
          },
        }}
      />

      {/* desc  */}
      <div className="heading_description">
        {" "}
        {/*style is comming form swap.css */}
        <div>
          <h1>Liquidity</h1>
          <p>
            In this remarkable Liquidity Feature, you have the exciting
            opportunity to participate in two distinct activities: adding
            liquidity to an existing pair, commonly referred to as farming, and
            generating passive income through LP token holdings. Alternatively,
            you can opt to initiate an entirely new pair for launch on the
            Decentralized Exchange platform.
            <br />
            <br />
            Liquidity, in the context of cryptocurrencies and financial
            instruments, denotes the ease with which these assets can be
            transacted in the market without causing significant price
            fluctuations.
            <br />
            <br />
            High liquidity signifies a robust presence of both buyers and
            sellers, fostering swift and seamless trade execution. Conversely,
            low liquidity may result in price volatility and challenges in
            executing substantial orders. The level of liquidity bears immense
            significance for traders and investors, exerting a substantial
            influence on market stability and the overall utility of these
            assets.
          </p>
        </div>
      </div>

      <section className="liquidity_main">
        <div className="liquidity_content_container">
          <CoinModal />
          <div className="liquidity_heading">
            <p>Add Liquidity</p>
            <div onClick={toggleSettingsBox}>
              <img src={liquiditysetting} alt="liquiditysetting" />
            </div>
            {isSettingsOpen && (
              <SettingModal
                isOpen={isSettingsOpen}
                onClose={toggleSettingsBox}
                slippage={slippage}
                setSlippage={setSlippage}
                deadline={deadline}
                setDeadline={setDeadline}
                autoSlippage={10}
              />
            )}
          </div>

          <div className="liquidity_deposit_money">
            <div className="liquidity_container">
              <div className="liquidity_sell_buy_item">
                <div className="liquidity_item">
                  <div className="liquidity_balance">
                    <div className="liquidity_amount">
                      <input
                        type="number"
                        placeholder="0"
                        className="amount_input"
                        onChange={(e) => handleInput1(e.target.value)}
                        value={amt1}
                      />
                      <p className="liquidity_approx">
                        approx: ${(ethInUsd * approx).toFixed(5)}
                      </p>
                    </div>
                    <div
                      className="liquidity_coin"
                      onClick={() =>
                        setCoinPanel((prev) => ({
                          isOpen: !prev.isOpen,
                          coin: 1,
                        }))
                      }
                    >
                      <div>
                        <img src={info1.img} alt="ethereum" />
                      </div>
                      <p>{info1.symbol}</p>
                    </div>
                  </div>
                </div>

                <div className="liquidity_left_right">
                  <img src={LiquidityPlus} alt="left_right_arrow" />
                </div>

                <div className="liquidity_item">
                  <div className="liquidity_balance">
                    <div className="liquidity_amount">
                      <input
                        type="number"
                        placeholder="0"
                        className="amount_input"
                        onChange={(e) => handleInput2(e.target.value)}
                        value={amt2}
                      />
                      <p className="liquidity_approx">
                        approx: ${(ethInUsd * approx).toFixed(5)}
                      </p>
                    </div>
                    <div
                      className="liquidity_coin"
                      onClick={() =>
                        setCoinPanel((prev) => ({
                          isOpen: !prev.isOpen,
                          coin: 2,
                        }))
                      }
                    >
                      <div>
                        <img src={info2.img} alt="usdt" />
                      </div>
                      <p>{info2.symbol}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="liquidity_three_container">
              <div className="liquidity_three_item">
                <p>{(amt2 / amt1).toFixed(5)}</p>
                <p>
                  {info2.symbol} per {info1.symbol}
                </p>
              </div>

              <div className="liquidity_three_item">
                <p>{(amt1 / amt2).toFixed(5)}</p>
                <p>
                  {info1.symbol} per {info2.symbol}
                </p>
              </div>

              <div className="liquidity_three_item">
                <p>{share}%</p>
                <p>Share of Pool</p>
              </div>
            </div>
            <button className="liquidity_button">
              {isConnected ? (
                <button onClick={handleAdd} disabled={loading}>
                  Add Liquidity
                </button>
              ) : (
                <ConnectButton />
              )}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Liquidity;
