import noe from './components/images/noe.jpg';
import bsc from "./tokens/bsc.json";
import eth from "./tokens/eth.json";
import poly from "./tokens/poly.json";

function convert(list) {
  return JSON.parse(JSON.stringify(list.tokens)).map((token) => {
    return {
      address: token.address,
      symbol: token.symbol,
      img: token.logoURI,
    };
  });
}

const bscTokens = convert(bsc);
const ethTokens = convert(eth);
const polyTokens = convert(poly);

const network = {
  56: {
    noeRouter: "0xB164eA87230Da8149b2439fE075b2309F3383af6",
    native: "BNB",
    router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    tokens: [
      {
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        symbol: "BNB",
        img: "https://tokens.pancakeswap.finance/images/symbol/bnb.png",
      },
      {
        address: "0xBbE626Be0abD64e8efd72A934F08ff9E81C909c8",
        symbol: "NOE",
        img: noe,
      },
      ...bscTokens,
    ],
  },
  97: {
    noeRouter: "0x897db1e9BBdb76C95DCc78e0e22B7138c96Cbe9e",
    native: "TBNB",
    router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    weth: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    tokens: [
      {
        address: "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551",
        symbol: "Cake2",
        img: "https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png",
      },
      {
        address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
        symbol: "TBNB",
        img: "https://assets.pancakeswap.finance/web/chains/56.png",
      },
      {
        address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
        symbol: "WBNB",
        img: "https://assets.pancakeswap.finance/web/chains/56.png",
      },
    ],
  },
  1: {
    noeRouter: "0xe8d453874F00E25FF03c818ed314628Fe3Cf07e2",
    native: "ETH",
    router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    weth: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    tokens: [
      {
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        symbol: "ETH",
        img: "https://assets.pancakeswap.finance/web/native/1.png",
      },
      ...ethTokens,
    ],
  },
  137: {
    noeRouter: "0xe8d453874F00E25FF03c818ed314628Fe3Cf07e2",
    native: "MATIC",
    router: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    weth: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    tokens: [
      {
        address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
        symbol: "MATIC",
        img: "https://app.uniswap.org/static/media/matic-token-icon.da7b877d41122a55de4a43760bb4c8e5.svg",
      },
      {
        address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
        symbol: "WMATIC",
        img: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png",
      },
      ...polyTokens,
    ],
  },
  undefined: {
    noeRouter: "0xB164eA87230Da8149b2439fE075b2309F3383af6",
    native: "BNB",
    router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    weth: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    tokens: [
      {
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        symbol: "BNB",
        img: "https://tokens.pancakeswap.finance/images/symbol/bnb.png",
      },
      {
        address: "0xBbE626Be0abD64e8efd72A934F08ff9E81C909c8",
        symbol: "NOE",
        img: noe,
      },
      ...bscTokens,
    ],
  },
};

export default network;
