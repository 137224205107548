import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { ColorRing } from 'react-loader-spinner';
import { useAccount, useNetwork } from 'wagmi';
import noeRouterAbi from '../../abi/noeRouterAbi.json';
import { AppContext } from '../../context/AppContext';
import network from '../../network';
import CoinModal from '../coinModal/CoinModal';
import swapsetting from '../images/Settings_swap.png';
import swap_left_right from '../images/swap_arrow_left_right.png';
import SettingModal from '../settingModal/SettingModal';
import './swap.css';

const Swap = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const toggleSettingsBox = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };
  const { chain } = useNetwork();
  const { isConnected, address } = useAccount();
  const [networkFee, setNetworkFee] = useState(0);
  const [balance1, setBalance1] = useState(0);
  const [balance2, setBalance2] = useState(0);

  const {
    tokenList,
    setTokenList,
    token1,
    token2,
    ethInUsd,
    amt1,
    amt2,
    approx,
    handleInput1,
    handleInput2,
    info1,
    info2,
    slippage,
    setSlippage,
    deadline,
    setDeadline,
    setCoinPanel,
    provider,
    signer,
    wethAddress,
    loading,
    setLoading,
    loaderStyle,
    router,
  } = useContext(AppContext);

  const noeRouter = new ethers.Contract(
    network[chain?.id].noeRouter,
    noeRouterAbi,
    signer
  );

  // calculate network fee
  useEffect(() => {
    (async () => {
      try {
        const gasPrice = await provider.getGasPrice();
        const gasFee = ethers.utils.formatEther(gasPrice.mul(21000));
        setNetworkFee(gasFee);
      } catch (error) {
        console.log('gas fee', error);
      }
    })();
  }, []);

  // calculate balance
  useEffect(() => {
    if (info1.symbol && info2.symbol) {
      getBalance();
    }
  }, [token1, token2, info1, info2]);

  async function getBalance() {
    try {
      let situation;
      if (info1.symbol === network[chain?.id].native) {
        let balance = await provider.getBalance(address);
        balance = ethers.utils.formatEther(balance);
        setBalance1(balance);
        situation = 1;
      }

      if (info2.symbol === network[chain?.id].native) {
        let balance = await provider.getBalance(address);
        balance = ethers.utils.formatEther(balance);
        setBalance2(balance);
        situation = 2;
      }

      let balance;
      switch (situation) {
        case 1:
          balance = await token2.balanceOf(address);
          balance = ethers.utils.formatEther(balance);
          setBalance2(balance);
          break;

        case 2:
          balance = await token1.balanceOf(address);
          balance = ethers.utils.formatEther(balance);
          setBalance1(balance);
          break;

        default:
          balance = await token1.balanceOf(address);
          balance = ethers.utils.formatEther(balance);
          setBalance1(balance);
          balance = await token2.balanceOf(address);
          balance = ethers.utils.formatEther(balance);
          setBalance2(balance);
          break;
      }
    } catch (error) {
      console.log('getbalance', error);
    }
  }

  /**
   * checks allowance and approves if needed
   * @param {*} token
   * @param {*} amount
   */
  async function checkApproved(token, amount) {
    const allowance = await token.allowance(address, noeRouter.address);
    if (allowance.lt(amount)) {
      const tx = await token.approve(
        noeRouter.address,
        ethers.constants.MaxUint256
      );
      await tx.wait();
    }
  }

  async function handleSwap() {
    setLoading(true);

    try {
      let txn,
        path = [token1.address, token2.address];
      const dl = Math.floor(Date.now() / 1000) + deadline * 60;

      if (
        info1.symbol === network[chain?.id].native &&
        token2.address === wethAddress
      ) {
        //  eth -> weth
        console.log('eth -> weth');
        txn = await noeRouter.swapEthToWeth({
          value: ethers.utils.parseEther(amt1),
          // gasPrice: await provider.getGasPrice(),
        });
        await txn.wait();
      } else if (
        token1.address === wethAddress &&
        info2.symbol === network[chain?.id].native
      ) {
        // weth -> eth
        console.log('weth -> eth');
        const amountIn = ethers.utils.parseEther(amt1);
        await checkApproved(token1, amountIn);
        txn = await noeRouter.swapWethToEth(amountIn, {
          // gasPrice: await provider.getGasPrice(),
        });
        await txn.wait();
      } else if (info1.symbol === network[chain?.id].native) {
        // eth -> token
        console.log('eth -> token');
        txn =
          await noeRouter.swapExactETHForTokensSupportingFeeOnTransferTokens(
            (
              await router.getAmountsOut(ethers.utils.parseEther(amt1), path)
            )[path.length - 1],
            path,
            dl,
            slippage * 10,
            {
              value: ethers.utils.parseEther(amt1),
              // gasPrice: await provider.getGasPrice(),
            }
          );
        await txn.wait();
      } else if (info2.symbol === network[chain?.id].native) {
        // token -> eth
        console.log('token -> eth');
        const amountIn = ethers.utils.parseEther(amt1);
        await checkApproved(token1, amountIn);
        txn =
          await noeRouter.swapExactTokensForETHSupportingFeeOnTransferTokens(
            (
              await router.getAmountsOut(amountIn, path)
            )[path.length - 1],
            amountIn,
            path,
            dl,
            slippage * 10
            // { gasPrice: await provider.getGasPrice() }
          );
        txn = await txn.wait();
      } else {
        // token -> token
        console.log('token -> token');
        const amountIn = ethers.utils.parseEther(amt1);
        await checkApproved(token1, amountIn);
        path =
          token1.address === wethAddress
            ? [wethAddress, token2.address]
            : token2.address === wethAddress
            ? [token1.address, wethAddress]
            : [token1.address, wethAddress, token2.address];
        txn =
          await noeRouter.swapExactTokensForTokensSupportingFeeOnTransferTokens(
            (
              await router.getAmountsOut(amountIn, path)
            )[path.length - 1],
            amountIn,
            path,
            dl,
            slippage * 10
            // { gasPrice: await provider.getGasPrice() }
          );
      }
      toast.success('Swap Successful');
    } catch (error) {
      console.dir(error);
      toast.error(error?.reason || error?.message);
    } finally {
      setLoading(false);
      await getBalance();
    }
  }

  // const swapDescription = {
  //   desc: `Introducing our NOE SWAP Feature: Trade any pairs on BSC, ETH, or POLYGON chains with total privacy. Stake 500,000 $NOE tokens in our Staking pools, and enjoy fee-free trading. $NOE Token fuels our DEX ecosystem, allowing seamless crypto swaps via smart contracts for peer-to-peer trading with enhanced privacy and security.`,
  // };

  return (
    <>
      <ColorRing
        height="60"
        width="60"
        ariaLabel="blocks-loading"
        colors={['#000']}
        wrapperStyle={loaderStyle}
        visible={loading}
      />
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
          style: {
            color: '#000',
            fontSize: '14px',
          },
        }}
      />

      {/* desc  */}
      <div className="heading_description">
        <div>
          <h1>Swap</h1>
          <p>
            Introducing our NOE SWAP Feature: Trade any pairs on BSC, ETH, or
            POLYGON chains with total privacy. Stake 500,000 $NOE tokens in our
            Staking pools, and enjoy fee-free trading. $NOE Token fuels our DEX
            ecosystem, allowing seamless crypto swaps via smart contracts for
            peer-to-peer trading with enhanced privacy and security.
            <br />
            <br />
            Join us for the future of crypto trading!
          </p>
        </div>
      </div>
      <section className="swap_main">
        <CoinModal />
        <div className="swap_content_container">
          <div className="swap_heading">
            <p>Swap</p>
            <div onClick={toggleSettingsBox}>
              <img src={swapsetting} alt="swapsetting" />
            </div>

            {isSettingsOpen && (
              <SettingModal
                isOpen={isSettingsOpen}
                onClose={toggleSettingsBox}
                slippage={slippage}
                setSlippage={setSlippage}
                deadline={deadline}
                setDeadline={setDeadline}
                autoSlippage={1}
              />
            )}
          </div>

          <div className="swap_deposit_money">
            <p className="swap_deposit_heading">Deposit Amount</p>

            <div className="swap_sell_buy_container">
              <div className="swap_sell_buy_item">
                <div className="swap_item">
                  <div className="swap_item_balance">
                    <p>You Sell</p>
                    <p>Balance: {Number(balance1).toFixed(5)}</p>
                  </div>
                  <div className="swap_balance">
                    <div className="swap_amount">
                      <input
                        type="number"
                        placeholder="0"
                        className="amount_input"
                        onChange={(e) => handleInput1(e.target.value)}
                        value={amt1}
                      />
                      <p className="swap_approx">
                        approx: ${(ethInUsd * approx).toFixed(5)}
                      </p>
                    </div>
                    <div
                      className="swap_coin"
                      onClick={() =>
                        setCoinPanel((prev) => ({
                          isOpen: !prev.isOpen,
                          coin: 1,
                        }))
                      }
                    >
                      <div>
                        <img src={info1.img} alt="ethereum" />
                      </div>
                      <p>{info1.symbol}</p>
                    </div>
                  </div>
                </div>

                <div
                  className="swap_left_right"
                  onClick={() => {
                    // swap index 0 and 1 of tokenlist
                    const temp = tokenList[0];
                    tokenList[0] = tokenList[1];
                    tokenList[1] = temp;
                    setTokenList([...tokenList]);
                  }}
                >
                  <img src={swap_left_right} alt="left_right_arrow" />
                </div>

                <div className="swap_item">
                  <div className="swap_item_balance">
                    <p>You buy</p>
                    <p>Balance: {Number(balance2).toFixed(5)}</p>
                  </div>
                  <div className="swap_balance">
                    <div className="swap_amount">
                      <input
                        type="number"
                        placeholder="0"
                        className="amount_input"
                        onChange={(e) => handleInput2(e.target.value)}
                        value={amt2}
                      />
                      <p className="swap_approx">
                        approx: ${(ethInUsd * approx).toFixed(5)}
                      </p>
                    </div>
                    <div
                      className="swap_coin"
                      onClick={() =>
                        setCoinPanel((prev) => ({
                          isOpen: !prev.isOpen,
                          coin: 2,
                        }))
                      }
                    >
                      <div>
                        <img src={info2.img} alt="usdt" />
                      </div>
                      <p>{info2.symbol}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swap_coin_details">
                <p>
                  1 {info1.symbol} = {(amt2 / amt1).toFixed(5)} ({info2.symbol})
                </p>
                <div>
                  <p>
                    <span>Expected outcome:</span>{' '}
                    <span>{Number(amt2).toFixed(5) || 0}</span>
                  </p>

                  <p>
                    <span>Network fee:</span>{' '}
                    <span>{Number(networkFee).toFixed(5)}</span>
                  </p>

                  <p>
                    <span>Minimum received after slippage:</span>{' '}
                    <span>
                      {Number(amt2 - (amt2 * slippage) / 100).toFixed(5)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <button className="swap_button">
              {' '}
              {isConnected ? (
                <button onClick={handleSwap} disabled={loading}>
                  Swap
                </button>
              ) : (
                <ConnectButton />
              )}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Swap;
